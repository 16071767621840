<template>
  <div class="product">
    <navbar></navbar>
    <div style="clear: both"></div>
    <div class="carousel" v-show="carouselList.length>0">
      <div id="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in carouselList" :key="item.id" @click="toDetail(item)" style="cursor: pointer;">
            <img :src="item.image" alt="" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
      </div>
    </div>
    <div class="summarize">
      <div class="content">
        <div class="item" v-for="item in areaList" :key="item.id">
          <div class="icon">
            <img :src="item.image" alt="" />
          </div>
          <div class="text">
            <h4 class="title">{{ item.title }}</h4>
            <div class="describe">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="featured-priducts" v-show="featuredProductsList.length>0">
      <div class="title">FEATURED PRODUCTS <span class="red">.</span></div>
      <div id="product-swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="item in featuredProductsList"
            :key="item.id"
            class="swiper-slide"
          >
            <div class="content">
              <div class="img" @click="toProductDetail(item.id)">
                <img :src="item.picture" alt="" />
              </div>
              <div class="feature-text">
                <div class="name" @click="toProductDetail(item.id)">
                  <span style="font-size: 16px"> {{ item.name }} </span>
                </div>
                <div class="index">Item # {{ item.code }}</div>
                <div class="price">
                  ${{ item.min_price}} - ${{ item.max_price }}
                </div>
                <div class="qty">MIN QTY: {{ item.min_quantity }}</div>
                <div class="" style="margin-top: 5px">
                  <el-button
                      @click="collect(item.id)"
                      :type="item.user_collect == 1 ? 'danger' : ''"
                      icon="el-icon-star-off"
                      size="mini"
                      circle
                      style="position: absolute; right: 40px; bottom: 15px"
                  ></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination" v-if="featuredProductsList.length > 5"></div>
        <div
          class="swiper-button-next swp1"
          v-if="featuredProductsList.length > 5"
          style="position: absolute; top: -15px; font-size: 14px !important"
        ></div>
        <div
          class="swiper-button-prev swp2"
          v-if="featuredProductsList.length > 5"
          style="position: absolute; top: -15px;left: auto;right: 50px"
        ></div>
      </div>
    </div>
    <div class="contentIntro">
      <div class="content">
        <div class="item" v-for="item in contentList" :key="item.id">
          <div class="icon">
            <img :src="item.image" alt="" />
          </div>
          <div class="text">
            <h4 class="title">{{ item.title }} <span style="color:red">.</span> </h4>
            <div class="describe" >
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brands-clients" style="margin-top: 0px">
      <div class="imgList">
        <div class="imgbox" v-for="item in clientsLsit" :key="item.id" @click="tobrandsDetails(item)">
          <img :src="item.image" alt=""/>
        </div>
      </div>
      <div  class="text clients_intro" :style="{ whiteSpace: 'pre-line' }">
        <p>With over a decade of experience in the industry, we understand that budget management, optimized imprinting, timely delivery, and product quality are crucial to the success of our clients' projects.</p>
        <p>Whether you require a small quantity or a large bulk order, our team is dedicated to offering creative and cost-effective solutions that align with your specific requirements and deadlines.</p>
        <br>
        <p>We pride ourselves on delivering professional service at every stage of the process, working closely with clients to ensure that every detail is executed flawlessly.</p>
        <br>
        <p>Our diverse product offerings cover everything from apparel, pens, and drinkware to a wide array of customized and personalized items. Explore our range of promotional products, and feel free to reach out—we're ready to assist with all your promotional needs!</p>
<!--        <p>SG SourcingI lnc. was founded in 2016.</p>-->
      </div>
    </div>
  </div>
</template>

  <script>
import navbar from "../components/navbar/index.vue";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import {productIndex, featuredProducts, collectProduct} from "@/api/product";
export default {
  name: "elegant",
  components: { navbar },
  data() {
    return {
      carouselList: [],
      areaList: [],
      featuredProductsList: [],
      contentList: [],
      clientsLsit: [],
      configList: "",
    };
  },
  mounted() {
    this.getproductIndex();
    this.getSwiper();
    this.getProductSwiper();
    this.getfeaturedProducts();
  },
  methods: {
    getSwiper() {
      this.headerSwiper = new Swiper("#swiper-container", {
        effect: "fade",
        // loop: true, // 无缝
        speed: 2000,
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        paginationClickable: true,
        slidesPerView: 1, // 一组三个
        spaceBetween: 15, // 间隔
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
    getProductSwiper() {
      this.swiper = new Swiper("#product-swiper-container", {
        loop: false, // 无缝
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        paginationClickable: true,
        slidesPerView: 5, // 一组三个
        spaceBetween: 8, // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swp1",
          prevEl: ".swp2",
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, // 分页器可以点击
        // },
        breakpoints: {
          //当宽度小于等于320
          320: {
            slidesPerView: 1,
            // spaceBetween: 50,
          },
          //当宽度小于等于480
          480: {
            slidesPerView: 2,
            // spaceBetween: 10,
          },
          //当宽度小于等于640
          640: {
            slidesPerView: 3, // 一组三个
            // spaceBetween: 10, // 间隔
          },
          //当宽度小于等于992
          //当宽度小于等于1200
          1200: {
            slidesPerView: 5, // 一组三个
            spaceBetween: 15, // 间隔
          },
        },
      });
    },
    getfeaturedProducts() {
      featuredProducts({token: localStorage.getItem("token"),price_type:localStorage.getItem("price_type"),is_sg:1}).then((res) => {
        this.featuredProductsList = res.data.list;
      });
    },
    getproductIndex() {
      productIndex().then((res) => {
        this.carouselList = res.data.carousel;
        this.areaList = res.data.area;
        this.contentList = res.data.content;
        this.clientsLsit = res.data.clients;
        this.configList = res.data.config;
      });
    },
    toProductDetail(id) {
      this.$router.push({
        path: "/product-detail",
        query: {
          id: id,
        },
      });
    },
    toDetail(item){
      if(item.link!=''){
        window.open(item.link)
      }
    },
    tobrandsDetails(item){
      if(item.link!=''){
        window.open(item.link)
      }
    },
    collect(id) {
      if (
          localStorage.getItem("token") &&
          localStorage.getItem("token") != ""
      ) {
        collectProduct({ id: id, token: localStorage.getItem("token") }).then(
            (res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getfeaturedProducts();
            }
        );
      } else {
        this.$confirm("Login Required", "Tips", {
          confirmButtonText: "OK",
          cancelButtonText: "Canecl",
          type: "warning",
        })
            .then(() => {
              this.$router.push({
                path: "login",
              });
            })
            .catch(() => {
            });
      }
    },
  },
};
</script>
<style lang="less" scoped>
// .navbar {
//   width: 1225px;
//   margin: 0 auto;
//   .navbar {
//     .category {
//       float: left;
//     }
//     .search {
//     }
//   }
// }
.carousel {
  background-color: #2c2f30;
  position: relative;
  #swiper-container {
    overflow: hidden;
    max-width: 1225px;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      height: auto;
    }
    /deep/ .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        background-color: #fff;
        opacity: 10;
      }
      .swiper-pagination-bullet-active {
        background-color: #da291c;
      }
      position: absolute;
      bottom: 25px;
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
      }
    }
    .swiper-wrapper {
      // background-color: #20616d;
      color: #fff;
    }
  }
}
.summarize {
  background-color: #ebeeef;

}




::v-deep .el-menu.el-menu--horizontal {
  border: none !important;
}
::v-deep .el-input__inner {
  border-radius: 50px;
  height: 40px;
  .el-icon-search {
    font-size: 50px;
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px;
  color: #fff;
}
.swiper-button-next {
  border-radius: .25rem;
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  border: 1px solid #666a6b;
}
.swiper-button-prev {
  border-radius: .25rem;
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  border: 1px solid #666a6b;
}
.swiper-button-disabled {
  border-radius: .25rem;
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  border: 1px solid #666a6b !important;
}
.clients_intro{
  //whiteSpace: 'pre-line';// 会保留文本中的换行符并进行换行。
  font-size: 15px;
  margin: 10px 0px;
  letter-spacing: 1px;
  color: #666a6b;
}
</style>
